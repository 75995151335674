import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { SuscriptionModalComponent } from './suscription-modal/suscription-modal.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { ActivateLoadingAction, DeactivateLoadingAction } from 'src/app/redux/ui.actions';


@Component({
  selector: 'app-suscriptions',
  templateUrl: './suscriptions.component.html',
  styleUrls: ['./suscriptions.component.scss']
})
export class SuscriptionsComponent implements OnInit {

  subscriptions = [];
  planId = "";
  userPlan = { billing_cycle_end: "", cancelled_at: 0 };

  constructor(private service: AuthService, public dialog: MatDialog, private store: Store<AppState>) { }

  ngOnInit() {
    this.service.getPlans().subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      console.log(decData.data.items)
      this.subscriptions = decData.data.items;
    })
    this.getCurrentSuscription();
  }

  getCurrentSuscription() {
    this.service.getCurrentSuscription().subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      console.log(decData.data.items[0]);
      let data = decData.data.items[0];
      this.userPlan = data;
      this.planId = data.plan.conekta_id;
    }, err => {
      let decData = JSON.parse(this.service.decrypt(err.error.message, ""));
      console.log(decData.error.errors[0])
      this.planId = "no_plan";
    });
  }

  addSubscription(sub) {
    console.log(sub)

    const dialogRef = this.dialog.open(SuscriptionModalComponent, {
      width: '500px',
      data: { sub, title: `Al dar click en aceptar se realizara un cargo de $${sub.amount / 100} a su tarjeta.` }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.store.dispatch(new ActivateLoadingAction());
        this.service.addSuscription(sub.id, sub).subscribe((d: any) => {

          let decData = JSON.parse(this.service.decrypt(d.message, "private"));
          this.store.dispatch(new DeactivateLoadingAction());
          Swal.fire({
            icon: 'success',
            title: 'Ya esta suscrito!!',
            text: "Tu suscripción puede verse reflejada hasta dentro de 2 horas",
          });
          // console.log(decData)
          this.getCurrentSuscription();

        }, err => {

          this.store.dispatch(new DeactivateLoadingAction());
          let decData = JSON.parse(this.service.decrypt(err.error.message, "private"));
          console.log(decData)
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: "Ha ocurrido un error",
          });
        });
      }

    });



  }

  freeSuscription() {

    const dialogRef = this.dialog.open(SuscriptionModalComponent, {
      width: '500px',
      data: { title: `Estas seguro de cancelar el plan? si lo cancela aun tendra sus terapias pendientes` }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.service.deleteSuscription().subscribe((d: any) => {
          let decData = JSON.parse(this.service.decrypt(d.message, "private"));
          Swal.fire({
            icon: 'success',
            title: 'Bien',
            text: "Ya cancelo su suscripción con éxito",
          });
          console.log(decData)
          this.getCurrentSuscription();
        }, err => {
          let decData = JSON.parse(this.service.decrypt(err.error.message));
          console.log(decData)
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: "Ha ocurrido un error",
          });
        });
      }
    });


  }


}
