import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { User } from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  userSubs: Subscription = new Subscription();
  uiSubs: Subscription = new Subscription();

  hide = true;
  loading = false;

  email = "";
  password = "";

  constructor( private authService:AuthService, private store: Store<AppState>, private router: Router) { }

  ngOnInit() {
    this.userSubs = this.store.select('user').subscribe( (data) => {
      if(data){
        this.router.navigate(['panel']);
      }
    } )

    this.uiSubs = this.store.select('ui').subscribe( ui=>{
      this.loading = ui.isLoading;
    } )
  }

  ngOnDestroy(){
    this.userSubs.unsubscribe();
  }

  login() {

    let user = {"email": (this.email),"password": (this.password),"time_zone": 'America/Regina',"role_id": "3","role":"patient"};

    let encryptUser = {data: this.authService.encrypt(user,"public")};

    this.authService.login(user);
  }



}
