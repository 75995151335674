import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { PaymentBookingComponent } from './payment-booking/payment-booking.component';
import * as momentTz from 'moment-timezone'
import * as moment from 'moment';

@Component({
  selector: 'app-new-booking',
  templateUrl: './new-booking.component.html',
  styleUrls: ['./new-booking.component.scss']
})
export class NewBookingComponent implements OnInit {

  timeLabel = "Seleccione una hora";
  time_zone = "";
  Today;
  planId = "no_plan";

  doctorId = "";
  doctorCatId = "";
  minDate = new Date();
  doctorTimeSlots = [];
  doctorDate = "";
  doctorTime = "";
  doctorFee = 0;
  cupon = "";
  cuponText = "";
  cuponValid = false;
  totalFee = 0;
  promo = 0;
  dateSelected = false;
  descuento = 0;

  reviewsList = [{ score:0, message:"" }];

  doctor = {
    account_status: "blocked",
    birth_at: 750492000000,
    city: "",
    country: "MX",
    country_code: "+52",
    country_code_flag: "",
    created_at: 1588688193873,
    deleted: false,
    email: "crowcl52@mind.mx",
    email_verified_at: 0,
    first_name: "Martin Eduardo",
    gender: "Male",
    id: 5,
    last_name: "Rubio",
    phone: "3324349525",
    photo_media_id: { url: "http://api.mindhelp.dev/a37d8de0-c122-454b-82e4-df8a480ba9af-1588688385455.png" },
    resume_media_id: { url: "http://api.mindhelp.dev/1656ae56-3d4f-4c13-af6b-63af26501f3d-1588688384869.pdf" },
    roles: [],
    services: [{ name: "", cost: 0 }],
    state: "",
    street_address: "",
    time_slots: [],
    time_zone: "America/Mexico_City",
    updated_at: 1589221054645,
    user_description: "todo bien",
    user_description_short: "",
    zip: ""
  }

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private service: AuthService,
    private router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.checkPromoCode();
    this.getCurrentSuscription();
    // agarrar el time zone
    var resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
    this.time_zone = resolvedOptions.timeZone;
    let date = new Date().getTime();

    this.doctorId = this.route.snapshot.paramMap.get("id");
    this.doctorCatId = this.route.snapshot.paramMap.get("cat_id");
    this.getDoctorDetail(this.doctorId);
    this.getReviews();
  }

  checkPromoCode(){
    let user = this.service.user;
    console.log(user)
    if( user.free_promo_code ){
      console.log(user.free_promo_code);
      this.cupon = user.free_promo_code.code;
    }
  }

  getDoctorDetail(doc_id) {
    this.service.getDoctorDetail(doc_id).subscribe((data: any) => {
      let decData = JSON.parse(this.service.decrypt(data.message,"private"));
      this.doctor = decData.data.items[0]
    }, err => {
      console.log(err)
      // this.presentAlert(err.error.msg);
    })
  }

  getDate(date) {

    this.doctorDate = moment(date).format('MM/DD/YYYY');

    this.service.getDoctorTimeList(this.doctorId, moment(date).valueOf()).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message,"public"));

      let slots = this.formatSlots(decData.data.items);

      let GMT = moment().format('ZZ');

      let newSlots = []
      slots.forEach( slot =>{
        console.log(moment(slot.start).format('LLL'))
        var dateIni = moment(slot.start);
        var dateFin = moment().add(30,'minutes');
        ////var dateIni = GMT.charAt(0) == '+' ? moment(slot.start).add(Number(GMT.slice(1,5))/100,"hours") : moment(slot.start).subtract(Number(GMT.slice(1,5))/100,"hours");
        ////var dateFin = moment().add(30,'minutes');
        if( moment(slot.start).format('DD-MM-YYYY') == moment(date).format('DD-MM-YYYY'))
        ////if( dateIni.format('DD-MM-YYYY') == moment(date).format('DD-MM-YYYY'))
          if(dateIni.isAfter(dateFin))
            newSlots.push( { start: moment(slot.start).format('HH:mm'), start_hour:moment(slot.start).format('h:mm a'), slot_type:slot.slot_type } );
            //newSlots.push( { start: moment(slot.start).format('HH:mm'), slot_type:slot.slot_type } );
            ////newSlots.push( { start: dateIni.format('HH:mm'), slot_type:slot.slot_type } );
      });

      // console.log(newSlots);
      this.doctorTimeSlots = newSlots;

      this.timeLabel = "Seleccione una hora";
      if (this.doctorTimeSlots.length == 0) {
        this.timeLabel = "No hay horas disponibles";
      } else if (this.Today != this.doctorDate) {

      }

    }, err => {
      console.log(err)
    });

  }

  formatSlots(slots){
    let newTimesS = [];
    slots.forEach(element => {
      // get the day of the mont
      let date = moment.utc(element.day ).format('MMM/DD/YYYY');

      // get the times in the day of the mont
      let newTimes = element.availability.map(elem => {

        let start = moment(elem.start_at_miliseconds).format();
        let plushour = moment(elem.start_at_miliseconds).add(1,'hour').format();

        let end =  moment(plushour).format()

        return {
          start: new Date(start),
          minutes: elem.minutes,
          slot_type: elem.slot_type
        }
      })

      newTimesS = newTimesS.concat(newTimes)

    });
    return newTimesS
  }

  getFee() {

    this.doctorFee = this.doctor.services[0].cost;
    this.totalFee = this.doctorFee;
    this.dateSelected = true;
    if( this.planId != "no_plan") {this.applyCupon();}
  }

  applyCupon() {
    this.cuponText = "";
    this.totalFee = this.doctorFee;

    let data = {
      code: this.cupon,
    }

    this.service.applyCupon(data).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message,"private"));

      console.log(decData.data.items[0])
      let data = decData.data.items[0]
      this.cuponValid = data.isValid;
      if(!this.cuponValid){
        this.resetCupon();
        this.cuponText = "Cupón NO válido";
        this.totalFee = this.doctorFee;
        return;
      }
      this.cuponText = "Cupon aplicado";

      if (data.flat_amount === 0) {
        this.promo = (this.totalFee * data.discount_percentage)
        this.descuento = (this.totalFee * data.discount_percentage)
      } else {
        this.promo = data.flat_amount;
        this.descuento = data.flat_amount;
      }

      console.log(this.promo)

      this.totalFee -= this.promo;

      console.log(this.totalFee)

    }, err => {
      console.log(err)
      this.resetCupon();
      this.cuponText = "El cupon no es valido";
      this.cuponValid = false;
      this.totalFee = this.doctorFee;
    })

  }

  saveBooking() {
    console.log(this.totalFee);
    if(this.totalFee <= 0)
      this.suscriptionBooking();
    else{
      //this.openDialog();
      Swal.fire({
        icon: 'warning',
        title: 'No se pudo completar la solicitud',
        text: 'Es necesario ingresar un cupón válido para agendar la cita',
      });
    }
    // return;
    ////if( this.planId != "no_plan" && this.cuponValid ){
    ////this.suscriptionBooking();
    ////}else{
      ////this.openDialog();
    ////}
  }

  openDialog() {
    const dialogRef = this.dialog.open(PaymentBookingComponent, {
      width: '800px',
      height: '600px',
      data: { isSold: false, index: null }
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log(result);

      if (result != undefined) {

        let time = this.doctorTime+":00";
        let date = this.doctorDate + " " + time;
        console.log(date)

        let start_at = momentTz(date).tz('UTC').format('x');
        console.log(start_at);

        let dateplus = moment(date).add(1,'hour').format('LLL');
        let end_at = momentTz(dateplus).tz('UTC').format('x');

        let data = {
          start_at,
          end_at,
          doctor_user_id: Number(this.doctorId),
          patient_user_id: this.service.user.id,
          base_price: this.doctorFee,
          final_price: this.totalFee,
          review: 5,
          promo_code: this.cupon,
          paymentmethod_id:1,
          index: result.index
        }

        console.log(data)
        // return;

        this.service.saveBooking(data).subscribe((d: any) => {
          console.log(d)
          Swal.fire({
            icon: 'success',
            title: 'Felicidades',
            text: "Tu Cita a sido aprobada con éxito",
          });
          this.router.navigate(['/panel/booking']);
        }, err => {
          Swal.fire({
            icon: 'error',
            title: 'Ha ocurrido un error',
            text: err.error.error.errors[0].message,
          });
        });
      }
    });
  }

  suscriptionBooking(){
    let time = this.doctorTime+":00";
    let date = this.doctorDate + " " + time;
    console.log(date)

    let start_at = momentTz(date).tz('UTC').format('x');
    console.log(start_at);

    let dateplus = moment(date).add(1,'hour').format('LLL');
    let end_at = momentTz(dateplus).tz('UTC').format('x');

    let data = {
      start_at,
      end_at,
      doctor_user_id: Number(this.doctorId),
      patient_user_id: this.service.user.id,
      base_price: this.doctorFee,
      final_price: this.totalFee,
      review: 5,
      promo_code: this.cupon,
      paymentmethod_id:1,
      index: 0
    }

    console.log(data)
    this.service.saveBooking(data).subscribe((d: any) => {
      console.log(d)
      Swal.fire({
        icon: 'success',
        title: 'Felicidades',
        text: "Tu Cita a sido aprobada con éxito",
      });
      this.router.navigate(['/panel/booking']);
    }, err => {
      console.log(err)
      let decUser = JSON.parse(this.service.decrypt(err.error.message, "private"));
      console.log(decUser)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: decUser.error.errors[0],
      });
    });
  }

  getReviews(){
    this.service.getReviews(this.doctorId).subscribe( (d:any) =>{
      let decData = JSON.parse(this.service.decrypt(d.message));

      this.reviewsList = decData.data.items;
    })
  }

  getCurrentSuscription(){
    this.service.getCurrentSuscription().subscribe( (d:any) =>{
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      console.log(decData.data.items[0]);
      let data = decData.data.items[0];
      this.planId = data.plan.conekta_id;
      this.cupon = data.promo_code.code;
    },err =>{
      let decData = JSON.parse(this.service.decrypt(err.error.message, ""));
      console.log(decData.error.errors[0])
      this.planId = "no_plan";
    });
  }

  resetCupon(){
    setTimeout(function(){
      this.cuponText = "";
      this.cupon = "";
    },3000);
  }

}
