import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { Categorie } from '../models/categorie.model';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ChangeTitleNav } from '../redux/ui.actions';
import { UnsetCategorieDoctorsAction } from '../redux/categories-doctors.actions';
import * as moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

  catSuscription: Subscription = new Subscription();
  docSuscription: Subscription = new Subscription();
  uisubs: Subscription = new Subscription();

  doctors = [];
  categories: Categorie[] = [];
  cat_Id;
  firstModal = true;
  isLoading = false;

  constructor( private store: Store<AppState>, private authService: AuthService, private router: Router ) {
    this.catSuscription = store.select('categories').subscribe(c => {
      this.categories = c.data;
      console.log(c.data)
    });
    
    this.docSuscription = store.select('cdoctors').subscribe(cd =>{
      this.doctors = cd.data;
      console.log(this.doctors)
    })
   }

  ngOnInit() {
    this.uisubs = this.store.select('ui').subscribe( ui=>{
      this.isLoading = ui.isLoading;
    });
  }

  ngAfterViewInit(){
    this.goCategorie(1,'cat1')
  }

  goCategorie(id, name){
    this.cat_Id = id;

    this.toogleCard(name);

    this.authService.getDoctors(id);
  }

  toogleCard(id){
    var sections = document.querySelectorAll('.img-container');
        for (let i = 0; i < sections.length; i++){
          sections[i].classList.remove('active-categori');
        }
    document.querySelector(`#${id}`).classList.add('active-categori');
  }

  bookingNow(id){
    this.router.navigate(['panel/booking-now',id,this.cat_Id]);
  }

  ngOnDestroy(){
    this.doctors = [];
    this.catSuscription.unsubscribe();
    this.docSuscription.unsubscribe();
    this.uisubs.unsubscribe();
    this.store.dispatch( new ChangeTitleNav( "" ) );
    this.store.dispatch( new UnsetCategorieDoctorsAction())

  }

  formatDate(d){
    let date = new Date(d)
    return moment(date, "YYYYMMDD").fromNow(true);
  }

}
