import { Component, OnInit, AfterViewChecked, AfterContentChecked } from '@angular/core';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import config from '../services/config';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';
import { ReBokingComponent } from '../new-booking/re-boking/re-boking.component';

@Component({
  selector: 'app-bookin',
  templateUrl: './bookin.component.html',
  styleUrls: ['./bookin.component.scss']
})

export class BookinComponent implements OnInit, AfterContentChecked {

  bookings = [];
  bookingsUpcoming = [];
  bookingsPast = [];
  today = new Date();
  time_zone = "";
  maxRangeDate = new Date().getTime() + (24 * 60 * 60 * 1000);

  constructor(private service: AuthService, private router: Router, public dialog: MatDialog ) { }

  ngOnInit() {
    var resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
    this.time_zone = this.service.user.time_zone ? this.service.user.time_zone : resolvedOptions.timeZone;

    this.getBookings("today");
    this.getBookingsUpcoming("upcoming");
    this.getBookingsPast("past");
  }

  ngAfterContentChecked(){
    // this.bookings.forEach( (elem, index) =>{
    //   let img = elem.doctor_user_id.photo_media_id;
    //   if( typeof img === 'number' ){
    //     this.service.getImgId(img).subscribe((d: any) => {
    //       this.bookings[index].doctor_user_id.photo_media_id = d.data.items[0].url
    //     });
    //   }
    // })

    // this.bookingsUpcoming.forEach( (elem, index) =>{
    //   let img = elem.doctor_user_id.photo_media_id;
    //   if( typeof img === 'number' ){
    //     this.service.getImgId(img).subscribe((d: any) => {
    //       this.bookingsUpcoming[index].doctor_user_id.photo_media_id = d.data.items[0].url
    //     });
    //   }
    // })

    // this.bookingsPast.forEach( (elem, index) =>{
    //   let img = elem.doctor_user_id.photo_media_id;
    //   if( typeof img === 'number' ){
    //     this.service.getImgId(img).subscribe((d: any) => {
    //       this.bookingsPast[index].doctor_user_id.photo_media_id = d.data.items[0].url
    //     });
    //   }
    // })
  }

  getBookings(type) {
    let maxDate = new Date().getTime() + (24 * 60 * 60 * 1000);

    this.service.datesScheduled(this.service.user.id).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));

      console.log(decData.data.items)
      let bookings = decData.data.items.filter(elem => elem.start_at < maxDate);

      this.bookings = bookings.map(elem => {
        return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
      })

      let upcoming = decData.data.items.filter(elem => elem.start_at > maxDate);
      let bookingsUpcoming = upcoming.map(elem => {
        return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
      });

      this.bookingsUpcoming = [...this.bookingsPast, ...bookingsUpcoming];

      this.service.datesInProgress(this.service.user.id).subscribe((d: any) => {
        let decData = JSON.parse(this.service.decrypt(d.message, "private"));

        let bookings = decData.data.items.map(elem => {
          return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
        })
        this.bookings = [...bookings, ...this.bookings];
      }, err => { console.log(err) });

    }, err => {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: err.error.msg,
      });
    });
  }

  getBookingsUpcoming(type) {
    this.service.datesToConfirm(this.service.user.id).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      console.log(decData.data.items)

      let bookingsUpcoming = decData.data.items.map(elem => {
        return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
      });

      this.bookingsUpcoming = [...this.bookingsUpcoming, ...bookingsUpcoming];
    }, err => {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: err.error.msg,
      });
    });
  }

  getBookingsPast(type) {
    let data = {
      type,
      time_zone: 'America/Regina'
    }
    this.service.endDatesFinish(this.service.user.id).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));

      console.log(decData.data.items)
      this.bookingsPast = decData.data.items;
    }, err => {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: err.error.msg,
      });
    });

  }

  cheackDates(date: string = "") {
    let tz = momentTZ.tz(date, this.time_zone).clone().tz(this.time_zone).format();
    let tzDay = momentTZ.tz(this.today, this.time_zone).format();

    return !((Date.parse(tz) - 300000) <= Date.parse(tzDay));
  }

  checkReDate(date){
    return (date < this.maxRangeDate);
  }

  goSession(b) {

    this.service.conectDate(b.patient_user_id.id, b.id).subscribe((d: any) => {//doctor_user_id.id
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));

      console.log(decData.data.items[0])
      config.SESSION_ID = decData.data.items[0].conference_session_token;
      config.TOKEN = decData.data.items[0].token;
      this.router.navigate(['/panel/video', b.id]);
    })
  }

  goChat(booking) {
    this.router.navigate(['/panel/chat']);
  }

  returnStatus(s) {
    let status = s;
    switch (s) {
      case 'scheduled_appointment':
        status = "Cita Agendada";
        break;
      case 'appointment_in_progress':
        status = "En progreso"
        break;
      case 'to_be_confirmed_by_therapist':
        status = "Por confirmar"
        break;
      default:
        status = s;
        break;
    }

    return status

  }

  refesh(){
    this.getBookings("today");
    this.getBookingsUpcoming("upcoming");
    this.getBookingsPast("past");
  }

  reDateBooking(doc){
    console.log(doc)
    const dialogRef = this.dialog.open(ReBokingComponent, {
      width: '800px',
      height: '600px',
      data: doc
    });
  }

}
