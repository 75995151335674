import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';

declare var conekta:any;

@Injectable({
  providedIn: 'root'
})
export class ConektaService {

  private newUrl = "https://api.mindhelp.mx/api/v1/";
  // private newUrl = "https://api.mindhelp.dev/api/v1/";
  token = "";

  constructor( private http: HttpClient, private authService: AuthService ) { 
    this.token = authService.token;
  }

  public getCards(id){
    let url = `${this.newUrl}users/${id}/payment/cards`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  public addCard(data,id){

    let url = `${this.newUrl}users/${id}/payment/cards`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.authService.encrypt(data,"private") };

    return this.http.post(url, encData, { headers });

  }

  public deleteCard(id,idCard){

    let url = `${this.newUrl}users/${id}/payment/cards/${idCard}`;

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.delete(url, { headers });
  }

}
