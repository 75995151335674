import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';

declare var Conekta: any;

var idUsusario = 0;

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit, OnDestroy {

  userSub: Subscription = new Subscription();
  userId = 0;

  tokenParams = {
    "card": {
      "number": "",
      "name": "",
      "exp_year": "",
      "exp_month": "",
      "cvc": "",
      "address": {
        "street1": "",
        "street2": "",
        "city": "",
        "state": "",
        "zip": "",
        "country": ""
      }
    }
  }

  public cardFG: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddCardComponent>,
    private _formBuilder: FormBuilder,
    private store: Store<AppState>,
    private service: AuthService) { }

  ngOnInit() {

    this.userSub = this.store.select('user').subscribe(d => {
      this.userId = d.data.id;
      idUsusario = d.data.id
      console.log(this.userId);
    })

    this.cardFG = this._formBuilder.group({
      number: ['', Validators.required],
      name: ['', Validators.required],
      exp_year: ['', Validators.required],
      exp_month: ['', Validators.required],
      cvc: ['', Validators.required],
      street1: ['', Validators.required],
      street2: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      country: ['', Validators.required],
    });
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  saveCard() {
    this.tokenParams = {
      "card": {
        "number": this.cardFG.value.number,
        "name": this.cardFG.value.name,
        "exp_year": this.cardFG.value.exp_year,
        "exp_month": this.cardFG.value.exp_month,
        "cvc": this.cardFG.value.cvc,
        "address": {
          "street1": this.cardFG.value.street1,
          "street2": this.cardFG.value.street2,
          "city": this.cardFG.value.city,
          "state": this.cardFG.value.state,
          "zip": this.cardFG.value.zip,
          "country": this.cardFG.value.country,
        }
      }
    }

    console.log(this.tokenParams.card);
    Conekta.Token.create(this.tokenParams, this.successResponseHandler.bind(this), this.errorResponseHandler);

  }


  async successResponseHandler(token) {
    // Do something on sucess
    // you need to send the token to the backend.
    let id = idUsusario;
    console.log(token.id, id)
    // develop
    // var url = `https://api.mindhelp.dev/api/v1/users/${id}/payment/cards`
    var url = `https://api.mindhelp.mx/api/v1/users/${id}/payment/cards`
    var data = { card_token: token.id };
    let encUser = { message: this.service.encrypt(data,"private") };

    await fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(encUser), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.service.token,
      }
    }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        console.log(response);
        Swal.fire({
          icon: 'success',
          title: 'Felicidades',
          text: "Tarjeta agregada con éxito",
        });
        this.onNoClick();
      });
  };


  errorResponseHandler(error) {
    // Do something on error
    console.log(error)
  };

  onNoClick(): void {
    this.dialogRef.close();
  }

}
